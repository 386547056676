import axios from 'axios';

const validateUser = async (email, password) => {
  try {
    const response = await axios.get(
      `https://devapi.qeeb.in/api/Authentication/ValidateUser?UserName=${email}&Password=${password}`
    );
    return response.data;
  } catch (error) {
    console.error("Error validating user", error);
    throw error;
  }
};

export default validateUser;
