export const faqData = [
    {
      question: "What unique design features does the Inspire Series offer?",
      answer: "The Inspire Series boasts a sleek, ultra-thin bezel design and a lightweight frame, making it aesthetically pleasing and easy to integrate into any space."
    },
    {
      question: "Does the Inspire Series support wireless screen sharing?",
      answer: "Yes, the Inspire Series includes built-in wireless screen sharing capabilities, allowing users to connect their devices effortlessly without the need for cables."
    },
    {
      question: "What advanced annotation tools are available in the Inspire Series?",
      answer: "The Inspire Series offers a suite of advanced annotation tools, including real-time collaborative drawing, shape recognition, and multi-user annotations for enhanced interactivity."
    },
    {
      question: "Is the Inspire Series equipped with built-in speakers?",
      answer: "Absolutely. The Inspire Series features high-fidelity built-in speakers, delivering clear and powerful audio for presentations and multimedia content."
    },
    {
      question: "Can the Inspire Series integrate with smart classroom technologies?",
      answer: "Yes, the Inspire Series seamlessly integrates with various smart classroom technologies, including interactive whiteboards, student response systems, and educational software."
    },
    {
      question: "What security features are included in the Inspire Series?",
      answer: "The Inspire Series incorporates robust security features such as encrypted data transmission, user authentication, and secure access controls to protect your information."
    },
    {
      question: "How does the Inspire Series enhance collaborative learning?",
      answer: "With multi-user touch support, real-time collaboration tools, and seamless device connectivity, the Inspire Series fosters an engaging and interactive learning environment."
    },
    {
      question: "What customization options are available for the Inspire Series software?",
      answer: "The Inspire Series software offers extensive customization options, including personalized interface layouts, customizable toolbars, and adaptable workflows to suit your specific needs."
    },
  ];
  