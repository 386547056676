import React from "react";
import Header from "../components/Header";
import SEO from "../components/SEO";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollTop";
import WhatsAppMsg from "../components/WhatsApp";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <>
      <div className="about-us-page">
        <SEO
          title="Refund Return Policy | Coltec Global"
          description="Learn how Coltec Global collects, uses, and protects your personal data in compliance with GDPR."
          keywords="Refund Return Policy, GDPR Compliance, Data Protection, Coltec Global"
          ogTitle="Refund Return Policy | Coltec Global"
          ogDescription="Understand Coltec Global's approach to data privacy and protection."
          ogUrl="https://coltec-global.com/refund-return-policy"
          ogType="website"
        />
        <Header />
        <div className="max-w-7xl px-5 lg:px-8 m-auto mx-auto mt-24 pb-12">
          <main className="p-10 bg-white shadow-lg border rounded-2xl">
            <div className=" text-gray-800">
              <h1 className="text-3xl font-bold text-center mb-6">
                Terms of Use
              </h1>
              <p className="mb-6">
                <strong>Effective Date:</strong> January 1, 2025
              </p>

              {/* Section 1 */}
              <section id="acceptance-of-terms" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  1. Acceptance of Terms
                </h2>
                <p className="mb-4">
                  By accessing, browsing, or using this Website in any way, you
                  acknowledge that you have read, understood, and agree to be
                  bound by these Terms and all applicable laws and regulations.
                  If you are using this Website on behalf of an organization,
                  you represent that you are authorized to accept these Terms on
                  behalf of that organization.
                </p>
              </section>

              {/* Section 2 */}
              <section id="changes-to-terms" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  2. Changes to Terms
                </h2>
                <p className="mb-4">
                  We reserve the right to update or modify these Terms at any
                  time without prior notice. Any changes will be effective
                  immediately upon posting to the Website. It is your
                  responsibility to review the Terms periodically. Your
                  continued use of the Website after any changes constitutes
                  your acceptance of the revised Terms.
                </p>
              </section>

              {/* Section 3 */}
              <section id="use-of-website" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  3. Use of the Website
                </h2>
                <p className="mb-4">
                  You agree to use the Website only for lawful purposes and in
                  compliance with these Terms. You may not:
                </p>
                <ol className="list-decimal list-inside space-y-2 ml-5">
                  <li>
                    Use the Website to engage in any fraudulent or illegal
                    activities.
                  </li>
                  <li>
                    Access or attempt to access non-public areas of the Website
                    without authorization.
                  </li>
                  <li>
                    Interfere with or disrupt the functionality or security of
                    the Website, servers, or networks.
                  </li>
                  <li>
                    Use automated systems (e.g., bots, spiders) to scrape or
                    access the Website without our written permission.
                  </li>
                </ol>
                <p className="mt-4">
                  We reserve the right to terminate or restrict your access to
                  the Website at our sole discretion.
                </p>
              </section>

              {/* Section 4 */}
              <section id="account-registration" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  4. Account Registration (If Applicable)
                </h2>
                <ul className="list-disc list-inside space-y-2 ml-5">
                  <li>
                    You must provide accurate and complete information during
                    registration.
                  </li>
                  <li>
                    You are responsible for maintaining the confidentiality of
                    your account credentials.
                  </li>
                  <li>
                    Notify us immediately if you suspect unauthorized access to
                    your account.
                  </li>
                  <li>
                    We reserve the right to suspend or terminate accounts that
                    violate these Terms.
                  </li>
                </ul>
              </section>

              {/* Section 5 */}
              <section id="intellectual-property-rights" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  5. Intellectual Property Rights
                </h2>
                <ul className="list-disc list-inside space-y-2 ml-5">
                  <li>
                    All content on this Website, including but not limited to
                    text, images, graphics, logos, videos, software, and
                    trademarks, is owned by or licensed to COLTEC – GLOBAL and
                    is protected under applicable intellectual property laws.
                  </li>
                  <li>
                    You are granted a limited, non-exclusive, non-transferable
                    license to access and use the Website for personal or
                    business purposes.
                  </li>
                  <li>
                    You may not copy, distribute, modify, or create derivative
                    works from the Website’s content without prior written
                    consent.
                  </li>
                </ul>
              </section>

              {/* Section 6 */}
              <section
                id="product-information-and-availability"
                className="mb-8"
              >
                <h2 className="text-2xl font-semibold mb-4">
                  6. Product Information and Availability
                </h2>
                <ul className="list-disc list-inside space-y-2 ml-5">
                  <li>
                    While we strive to provide accurate and up-to-date
                    information about our products and services, errors may
                    occur. We reserve the right to correct errors, inaccuracies,
                    or omissions and to update or discontinue products without
                    notice.
                  </li>
                  <li>
                    Prices, specifications, and availability are subject to
                    change at any time without prior notice.
                  </li>
                </ul>
              </section>

              {/* Section 7 */}
              <section id="purchases-and-payments" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  7. Purchases and Payments
                </h2>
                <ul className="list-disc list-inside space-y-2 ml-5">
                  <li>
                    All purchases made through the Website are subject to our{" "}
                    <Link
                      to="/refund-return-policy"
                      className="text-blue-600 hover:underline"
                    >
                      Refund & Return Policy
                    </Link>{" "}
                    and any additional terms provided at the time of purchase.
                  </li>
                  <li>
                    We accept payments through authorized methods and secure
                    payment gateways. By providing payment information, you
                    confirm that you are authorized to use the payment method.
                  </li>
                  <li>
                    We are not responsible for payment failures resulting from
                    third-party services.
                  </li>
                </ul>
              </section>

              {/* Section 8 */}
              <section id="user-generated-content" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  8. User-Generated Content
                </h2>
                <p className="mb-4">
                  If you submit content (e.g., reviews, comments) to the
                  Website:
                </p>
                <ol className="list-decimal list-inside space-y-2 ml-5">
                  <li>
                    You grant us a non-exclusive, royalty-free, perpetual
                    license to use, modify, reproduce, and distribute your
                    content.
                  </li>
                  <li>
                    You represent that your content does not violate the rights
                    of any third party or applicable laws.
                  </li>
                  <li>
                    We reserve the right to remove or edit user-submitted
                    content that violates these Terms.
                  </li>
                </ol>
              </section>

              {/* Section 9 */}
              <section id="privacy-policy" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  9. Privacy Policy
                </h2>
                <p className="mb-4">
                  Your use of the Website is also governed by our{" "}
                  <Link
                    to="/privacy-policy"
                    className="text-blue-600 hover:underline"
                  >
                    Privacy Policy
                  </Link>
                  , which explains how we collect, use, and protect your
                  personal data.
                </p>
              </section>

              {/* Section 10 */}
              <section id="third-party-links" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  10. Third-Party Links
                </h2>
                <p className="mb-4">
                  The Website may include links to third-party websites or
                  services. These links are provided for convenience only, and
                  we do not endorse or assume responsibility for the content,
                  policies, or practices of any third-party websites.
                </p>
              </section>

              {/* Section 11 */}
              <section id="disclaimer-of-warranties" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  11. Disclaimer of Warranties
                </h2>
                <ul className="list-disc list-inside space-y-2 ml-5">
                  <li>
                    The Website and its content are provided on an “as-is” and
                    “as-available” basis without warranties of any kind, whether
                    express or implied.
                  </li>
                  <li>
                    We do not guarantee that the Website will be error-free,
                    uninterrupted, or free from harmful components.
                  </li>
                </ul>
              </section>

              {/* Section 12 */}
              <section id="limitation-of-liability" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  12. Limitation of Liability
                </h2>
                <p className="mb-4">
                  To the fullest extent permitted by law, COLTEC – GLOBAL and
                  its affiliates shall not be liable for any indirect,
                  incidental, consequential, or special damages arising out of
                  your use or inability to use the Website or its content.
                </p>
              </section>

              {/* Section 13 */}
              <section id="indemnification" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  13. Indemnification
                </h2>
                <p className="mb-4">
                  You agree to indemnify and hold harmless COLTEC – GLOBAL, its
                  officers, employees, and affiliates from any claims, damages,
                  losses, or expenses arising from your violation of these Terms
                  or misuse of the Website.
                </p>
              </section>

              {/* Section 14 */}
              <section id="termination" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">14. Termination</h2>
                <p className="mb-4">
                  We reserve the right to terminate your access to the Website
                  at our discretion, without notice, for any violation of these
                  Terms or other reasons deemed appropriate by us.
                </p>
              </section>

              {/* Section 15 */}
              <section id="governing-law-and-jurisdiction" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  15. Governing Law and Jurisdiction
                </h2>
                <p className="mb-4">
                  These Terms are governed by and construed in accordance with
                  the laws of India. Any disputes arising from or related to
                  these Terms shall be subject to the exclusive jurisdiction of
                  the courts in Hyderabad, Telangana.
                </p>
              </section>

              {/* Section 16 */}
              <section id="contact-us" className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">16. Contact Us</h2>
                <p className="mb-4">
                  For questions or concerns regarding these Terms, you can reach
                  us at:
                </p>
                <ul className="list-disc list-inside space-y-2 ml-5">
                  <li>
                    Email:{" "}
                    <a
                      href="mailto:support@coltec-global.com"
                      className="text-blue-600 hover:underline"
                    >
                      support@coltec-global.com
                    </a>
                  </li>
                  <li>Phone: Toll Free +91 1800 309 8822</li>
                </ul>
              </section>
            </div>
          </main>
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default TermsOfUse;
