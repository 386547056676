import React, { useState } from "react";

const FAQ = ({ data }) => {
  // Track which FAQ index is open (or null if none)
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function
  const handleToggle = (index) => {
    // If the clicked item is already open, close it by setting openIndex to null
    // Otherwise, set openIndex to the clicked item
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <>
      <div className="w-full">
        <h2 className="md:text-[40px] text-[28px] text-center font-normal mb-8 text-gray-900">
          Frequently Asked Questions (FAQs)
        </h2>
        <div className="divide-y divide-gray-300 border-t border-b border-gray-300">
          {data.map((item, index) => {
            const isOpen = openIndex === index;

            return (
              <div
                key={index}
               
              >
                {/* Question row */}
                <button
                  type="button"
                  onClick={() => handleToggle(index)}
                  className={`transition-colors w-full flex items-center justify-between py-4 px-5 text-left ${
                    isOpen ? "bg-[#e2eafe]" : "bg-white"
                  }`}
                >
                  <span className="font-medium text-base text-gray-800">
                    {item.question}
                  </span>

                  {/* Icon (rotates when open) */}
                  <svg
                    className={`h-5 w-5 text-blue-500 transform transition-transform ${
                      isOpen ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {/* Answer section (conditional rendering) */}
                {isOpen && (
                  <div className="px-5 py-5 text-[#767982] text-sm">{item.answer}</div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FAQ;
