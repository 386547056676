export const sendQuotation = async (email) => {
    const apiUrl = `https://devapi.qeeb.in/api/Coltec/SendQuotation?Email=${encodeURIComponent(email)}`;
  
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send enquiry");
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };
  