export const faqData = [
    {
      question: "What screen sizes are available in the Rise Series?",
      answer: "The Rise Series offers screen sizes from 65 inches to 98 inches, suitable for various environments like classrooms and large meeting rooms."
    },
    {
      question: "Does the Rise Series support 4K Ultra HD resolution?",
      answer: "Yes, all Rise Series models support 4K Ultra HD resolution, ensuring clear and vibrant visuals for all your presentations and media."
    },
    {
      question: "What connectivity options does the Rise Series provide?",
      answer: "The Rise Series includes HDMI, USB, DisplayPort, Bluetooth, Wi-Fi, and Ethernet ports, allowing seamless integration with multiple devices."
    },
    {
      question: "Is the Rise Series compatible with major video conferencing platforms?",
      answer: "Absolutely. It is fully compatible with Microsoft Teams, Zoom, Google Meet, and Cisco Webex, facilitating smooth virtual meetings."
    },
    {
      question: "What touch technology is used in the Rise Series?",
      answer: "The Rise Series uses advanced infrared touch technology, supporting up to 20 simultaneous touch points for interactive and collaborative use."
    },
    {
      question: "Can the Rise Series be wall-mounted or placed on a stand?",
      answer: "Yes, it supports VESA wall mounting standards and can also be placed on mobile or fixed stands, offering versatile installation options."
    },
    {
      question: "What warranty is included with the Rise Series?",
      answer: "Coltec provides a comprehensive 3-year warranty covering parts, labor, and technical support to ensure long-term reliability and performance."
    },
    {
      question: "How energy-efficient is the Rise Series?",
      answer: "The Rise Series is designed for energy efficiency with low-power modes and automatic brightness adjustment to minimize energy consumption."
    },
  ];
  