export const faqData = [
    {
      question: "What screen sizes are available in the Evoke Series?",
      answer: "The Evoke Series offers a range of screen sizes from 55 inches to 86 inches, catering to various room sizes and usage needs."
    },
    {
      question: "Does the Evoke Series support 4K resolution?",
      answer: "Yes, all models in the Evoke Series support 4K Ultra HD resolution, providing crisp and vibrant display quality."
    },
    {
      question: "What connectivity options are available on the Evoke panels?",
      answer: "Evoke panels come equipped with multiple connectivity options including HDMI, USB, Bluetooth, Wi-Fi, and built-in Ethernet ports to ensure seamless integration with other devices."
    },
    {
      question: "Is the Evoke Series compatible with major collaboration software?",
      answer: "Absolutely. The Evoke Series is compatible with popular collaboration tools such as Microsoft Teams, Zoom, Google Meet, and more, facilitating smooth virtual meetings and collaborations."
    },
    {
      question: "What touch technology does the Evoke Series use?",
      answer: "The Evoke Series utilizes advanced multi-touch technology that supports up to 20 simultaneous touch points, enabling interactive and collaborative experiences."
    },
    {
      question: "Can the Evoke Series be mounted on a wall?",
      answer: "Yes, the Evoke Series is designed with VESA mounting standards, allowing for easy wall mounting or installation on mobile stands as per your requirements."
    },
    {
      question: "What warranty is provided with the Evoke Series?",
      answer: "Coltec offers a comprehensive 3-year warranty on the Evoke Series, covering parts, labor, and technical support to ensure long-term reliability and performance."
    },
    {
      question: "How energy-efficient are the Evoke Series panels?",
      answer: "The Evoke Series is designed with energy efficiency in mind, featuring low-power consumption modes and eco-friendly materials to reduce overall energy usage without compromising performance."
    },
  ];
  