import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Existing user state
  const [user, setUser] = useState(null);

  // New state for Sign-In Modal
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  // Function to open the sign-in modal
  const openSignInModal = () => setIsSignInModalOpen(true);

  // Function to close the sign-in modal
  const closeSignInModal = () => setIsSignInModalOpen(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isSignInModalOpen,      // Expose modal state
        openSignInModal,        // Expose function to open modal
        closeSignInModal,       // Expose function to close modal
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
