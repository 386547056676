export const showToast = (message, type = "success") => {
    const toastContainer = document.createElement("div");
    toastContainer.classList.add("toast", type);
  
    const toastMessage = document.createElement("p");
    toastMessage.textContent = message;
  
    toastContainer.appendChild(toastMessage);
    document.body.appendChild(toastContainer);
  
    setTimeout(() => {
      toastContainer.classList.add("toast-fade");
      setTimeout(() => {
        document.body.removeChild(toastContainer);
      }, 500);
    }, 3000);
  };
  