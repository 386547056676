import { format, formatDistanceToNow } from 'date-fns';

export const formatCommentDate = (date) => {
  const now = new Date();
  const commentDate = new Date(date);
  const differenceInSeconds = (now - commentDate) / 1000;

  // Define a threshold (e.g., show relative time for comments within the last 7 days)
  const SEVEN_DAYS_IN_SECONDS = 7 * 24 * 60 * 60;

  if (differenceInSeconds < SEVEN_DAYS_IN_SECONDS) {
    return formatDistanceToNow(commentDate, { addSuffix: true });
  } else {
    return format(commentDate, 'MMM d, yyyy');
  }
};
