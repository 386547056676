import React, { useState, useEffect, useRef, useContext } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { registerUser } from "../utils/userRegistration";
import validateUser from "../utils/getUserLogin";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Import eye icons
import { UserContext } from "../utils/UserContext";
import { LuShoppingCart } from "react-icons/lu";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Products" },
  { name: "Solutions" },
  { name: "About Us", href: "/about-us" },
  { name: "Media", href: "/media" },
  { name: "Connect", href: "/connect" },
];

const solutionsDropdown = [
  { name: "Education", href: "/solutions/education" },
  { name: "Corporate", href: "/solutions/corporate" },
];

const productsDropdown = [{ name: "Interactive Flat Panel" }];

const interactiveFlatPanelDropdown = [
  {
    name: "Evoke Series",
    href: "/products/interactive-flat-panel/evoke-series",
  },
  { name: "Rise Series", href: "/products/interactive-flat-panel/rise-series" },
  {
    name: "Inspire Series",
    href: "/products/interactive-flat-panel/inspire-series",
  },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [openMenu, setOpenMenu] = useState(null); // Controls which menu is open
  const [interactiveDropdownOpen, setInteractiveDropdownOpen] = useState(false); // Sub-dropdown inside Products
  const location = useLocation();
  const [isSignUp, setIsSignUp] = useState(false); // New state for Sign-In/Sign-Up toggle
  const [currentStep, setCurrentStep] = useState(1); // New state for multi-step Sign-Up
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNo: "",
    password: "",
    organizationName: "",
    address: "",
  });
  const [avatarDropdownOpen, setAvatarDropdownOpen] = useState(false);
  const [isUserPassword, setUserPassword] = useState(false); // State for password visibility
  const timerRef = useRef(null);
  const [toast, setToast] = useState({
    showToast: false,
    message: "",
    type: "",
  });
  // Consume UserContext
  const {
    user,
    setUser,
    isSignInModalOpen,
    openSignInModal,
    closeSignInModal,
  } = useContext(UserContext);

  // Function to handle opening and closing of menus
  const handleMenuClick = (menuName) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
    setInteractiveDropdownOpen(false); // Close sub-dropdown when another menu is toggled
  };

  useEffect(() => {
    // Automatically open the modal once by default
    const hasSeenModal = localStorage.getItem("hasSeenSignInModal");
    if (!hasSeenModal) {
      openSignInModal();
      localStorage.setItem("hasSeenSignInModal", "true");
    }
  }, [openSignInModal]);

  useEffect(() => {
    if (user) {
      // If user is logged in, ensure no timer is running
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      return; // Exit early since user is logged in
    }

    const interactionHandler = () => {
      if (!timerRef.current) {
        timerRef.current = setTimeout(() => {
          // setSignInModalOpen(true);
        }, 5000); // 5 seconds
      }
    };

    // List of events to listen to
    const events = ["scroll", "mousemove", "keydown", "touchstart"];

    // Attach event listeners
    events.forEach((event) =>
      window.addEventListener(event, interactionHandler)
    );

    // Cleanup function to remove event listeners and clear the timer
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, interactionHandler)
      );
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [user, openSignInModal]); // Added 'user' as a dependency
  // Added 'user' as a dependency

  useEffect(() => {
    if (isSignInModalOpen && timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [isSignInModalOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  const handleInteractiveClick = () => {
    setInteractiveDropdownOpen(!interactiveDropdownOpen);
  };

  // Load user from localStorage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, [setUser]);

  // Save user to localStorage whenever it changes
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  const handleGoogleSuccess = async (credentialResponse) => {
    if (credentialResponse.credential) {
      try {
        // Decode the JWT token to get user information
        const decoded = jwtDecode(credentialResponse.credential);
        console.log("Google User:", decoded);

        // Set User State
        setUser({
          name: decoded.name,
          email: decoded.email,
          avatar: decoded.picture, // Google provides a picture URL
          loginMethod: "google",
        });
        // Store the email separately in localStorage (optional)
        localStorage.setItem("email", decoded.email);

        closeSignInModal(); // Close the modal after successful sign-in
        setIsSignUp(false); // Reset to Sign-In view
        setCurrentStep(1); // Reset to Step 1 in Sign-Up
        setFormData({
          userName: "",
          email: "",
          phoneNo: "",
          password: "",
          organizationName: "",
          address: "",
        }); // Reset form data
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleGoogleError = () => {
    console.error("Google Sign-In was unsuccessful. Try again later.");
  };

  const handleLogout = () => {
    googleLogout();
    setUser(null);
    setAvatarDropdownOpen(false);
    // Optionally, perform additional cleanup or redirect
    localStorage.removeItem("email"); // Removes the separate email key if used
  };

  // Handle input changes for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle Sign-Up form submission
  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    if (currentStep === 1) {
      // Validate Step 1 fields
      const { userName, email, phoneNo, password } = formData;
      if (!userName || !email || !phoneNo || !password) {
        setToast({
          showToast: true,
          message: "Please fill in all required fields.",
          type: "error",
        });
        setTimeout(
          () => setToast({ showToast: false, message: "", type: "" }),
          2000
        );
        return;
      }
      // Proceed to Step 2
      setCurrentStep(2);
    } else if (currentStep === 2) {
      // Validate Step 2 fields
      const { organizationName, address } = formData;
      if (!organizationName || !address) {
        setToast({
          showToast: true,
          message: "Please fill in all required fields.",
          type: "error",
        });
        setTimeout(
          () => setToast({ showToast: false, message: "", type: "" }),
          2000
        );
        return;
      }
      // Final submission logic here (Step 2)
      try {
        const response = await registerUser(formData);
        // After successful registration:
        if (response.status === 1) {
          setToast({
            showToast: true,
            message: "User Registered Successfully.",
            type: "success",
          });

          // Show the toast for 2 seconds, then open Sign-In modal
          setTimeout(() => {
            setToast({ showToast: false, message: "", type: "" });
            openSignInModal(); // Open the Sign-In modal after successful registration
          }, 2000); // Delay modal opening for 2 seconds
        } else {
          setToast({
            showToast: true,
            message: "User registration failed. Please try again.",
            type: "error",
          });
          setTimeout(
            () => setToast({ showToast: false, message: "", type: "" }),
            2000
          );
        }

        // For example, send data to your backend API
        console.log("Form Data Submitted:", formData);

        // Reset modal
        closeSignInModal();
        setIsSignUp(false);
        setCurrentStep(1);
        setFormData({
          userName: "",
          email: "",
          phoneNo: "",
          password: "",
          organizationName: "",
          address: "",
        });
      } catch (error) {
        alert("User registration failed. Please try again.");
      }
    }
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = formData;
    if (!email || !password) {
      setToast({
        showToast: true,
        message: "Please fill in both email and password.",
        type: "error",
      });
      setTimeout(
        () => setToast({ showToast: false, message: "", type: "" }),
        2000
      );
      return;
    }

    try {
      const userData = await validateUser(email, password);
      if (userData.status === 1) {
        setToast({
          showToast: true,
          message: "Sign-In Successful!",
          type: "success",
        });
        setTimeout(
          () => setToast({ showToast: false, message: "", type: "" }),
          2000
        );
        // Set User State with loginMethod
        setUser({
          name: userData.userName, // Assuming the response includes the user's name
          email: userData.email,
          loginMethod: "manual",
        });
        // Store the email separately in localStorage (optional)
        localStorage.setItem("email", userData.email);
        // Close the modal after successful sign-in
        closeSignInModal(); // Close the modal
        setIsSignUp(false); // Reset to Sign-In view
        setFormData({ email: "", password: "" }); // Reset form data
      } else {
        setToast({
          showToast: true,
          message: "Invalid credentials, please try again.",
          type: "error",
        });
        setTimeout(
          () => setToast({ showToast: false, message: "", type: "" }),
          2000
        );
      }
    } catch (error) {
      setToast({
        showToast: true,
        message: "Error during sign-in. Please try again.",
        type: "error",
      });
      setTimeout(
        () => setToast({ showToast: false, message: "", type: "" }),
        2000
      );
    }
  };

  return (
    <>
      <header
        className={`inset-x-0 top-0 z-50 border-white/30 bg-gradient-radial backdrop-blur-md border-b ${
          isFixed ? "fixed fadeInDown bg-white fixed-nav" : "absolute nav-text"
        }`}
      >
        <nav
          aria-label="Global"
          className="flex items-center lg:justify-center justify-between px-5 lg:px-8 max-w-7xl m-auto"
        >
          <div className="flex lg:flex-1 py-3.5">
            <Link to="/">
              <img
                alt="Coltec Logo"
                width="130"
                height="28"
                src={
                  isFixed
                    ? "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                    : "https://content.qeeb.in/coltec/coltec-logo-white.svg"
                }
                className="md:h-7"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <div className="flex space-x-3 items-center">
              <div className="bg-white hover:bg-orange-400 border border-gray-200 hover:border-orange-400 text-gray-800 text-sm px-2 py-[6px] rounded-lg shadow-lg hover:translate-y-[-2px] transition-all ease-linear duration-300">
                <Link to="/products">
                  <img
                    src="https://content.qeeb.in/coltec/products/shop-icon.svg"
                    alt="cart-icon"
                    title="Shop Now"
                    className="h-[18px] invert"
                  />
                </Link>
              </div>
              {/* Conditional Rendering Starts Here */}
              {/* Conditional Rendering Starts Here */}
              {user ? (
                <div className="relative lg:pr-0 pr-3">
                  {user.loginMethod === "google" ? (
                    // Google User Dropdown
                    <button
                      className="flex items-center space-x-2 text-sm rounded-full transition-all ease-linear duration-300"
                      onClick={() => setAvatarDropdownOpen(!avatarDropdownOpen)}
                    >
                      <img
                        src={user.avatar}
                        alt={user.name}
                        className="h-8 w-8 rounded-full"
                      />
                    </button>
                  ) : (
                    // Manual Login User Icon
                    <button
                      className="flex items-center space-x-2 rounded-full transition-all ease-linear duration-300"
                      onClick={() => setAvatarDropdownOpen(!avatarDropdownOpen)}
                    >
                      <img
                        src="https://content.qeeb.in/coltec/default-avatar.png"
                        alt={user.userName}
                        className="h-8 w-8 rounded-full"
                      />
                    </button>
                  )}
                  {/* Dropdown Menu */}
                  {avatarDropdownOpen && (
                    <div className="absolute right-0 mt-4 border border-gray-200 w-40 bg-white rounded-md shadow-lg p-3 z-20">
                      <Link to="/products/cart" className="flex text-sm font-medium mb-3 group">
                        <LuShoppingCart className="text-lg mr-2" />
                        <span className="group-hover:text-blue-500">View cart</span>
                      </Link>
                      <button
                        onClick={() => {
                          handleLogout();
                          setAvatarDropdownOpen(false);
                        }}
                        className="flex items-center text-left text-sm font-medium text-gray-800 hover:text-red-600"
                      >
                        <img
                          src="https://content.qeeb.in/coltec/products/logout-icon.svg"
                          alt="Logout"
                          className="h-[14px] mr-2"
                        />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="pr-3 lg:pr-0">
                  <button
                    onClick={() => {
                      openSignInModal();
                      setIsSignUp(false); // Ensure modal starts with Sign-In view
                      setCurrentStep(1); // Reset to Step 1 when opening
                      setFormData({
                        name: "",
                        email: "",
                        mobileNumber: "",
                        password: "",
                        originationName: "",
                        location: "",
                      }); // Reset form data
                    }}
                    className="bg-white hover:bg-orange-400 border border-gray-200 hover:border-orange-400 text-gray-800 text-sm px-3 py-[6px] rounded-lg shadow-lg transition-all ease-linear duration-300"
                  >
                    Sign in
                  </button>
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ${
                isFixed ? "text-gray-400" : "text-white"
              }`}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-8 w-8" />
            </button>
          </div>
          <div className="hidden lg:flex items-center lg:gap-x-12">
            {navigation.map((item) =>
              item.name === "Solutions" ? (
                <div key={item.name} className="relative">
                  <button
                    onClick={() => handleMenuClick("solutions")}
                    className="text-sm transition-all link font-medium leading-6 text-gray-950"
                  >
                    {item.name}
                  </button>
                  {openMenu === "solutions" && (
                    <div className="absolute left-0 right-0 mt-5 w-36 bg-white shadow-lg rounded-md ring-1 ring-gray-900/5 fadeInDown">
                      <div className="py-1 dropdown-link">
                        {solutionsDropdown.map((dropdownItem) => (
                          <Link
                            key={dropdownItem.name}
                            to={dropdownItem.href}
                            className="block px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                          >
                            {dropdownItem.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : item.name === "Products" ? (
                <div key={item.name} className="relative">
                  <button
                    onClick={() => handleMenuClick("products")}
                    className="text-sm transition-all link font-medium leading-6 text-gray-950"
                  >
                    {item.name}
                  </button>
                  {openMenu === "products" && (
                    <div className="absolute left-0 right-0 mt-5 w-48 bg-white shadow-lg rounded-md ring-1 ring-gray-900/5 fadeInDown">
                      <div className="py-1 dropdown-link">
                        {productsDropdown.map((product) =>
                          product.name === "Interactive Flat Panel" ? (
                            <div key={product.name} className="relative">
                              <button
                                onClick={handleInteractiveClick}
                                className="block relative px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                              >
                                {product.name}{" "}
                                <img
                                  alt="arrow-right"
                                  src="https://content.qeeb.in/coltec/chevron-right.svg"
                                  className="chevron-right lg:inline-block hidden absolute top-[10px] ml-[5px] w-[15px]"
                                />
                              </button>
                              {interactiveDropdownOpen && (
                                <div className="absolute left-full top-0 -mt-1 ml-[2px] w-36 bg-white shadow-lg rounded-md ring-1 ring-gray-900/5 fadeInDown">
                                  <div className="py-1">
                                    {interactiveFlatPanelDropdown.map(
                                      (item) => (
                                        <Link
                                          key={item.name}
                                          to={item.href}
                                          className="block px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                                        >
                                          {item.name}
                                        </Link>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <Link
                              key={product.name}
                              to={product.href}
                              className="block px-4 py-2 text-sm text-gray-950 hover:bg-sky-100 link font-medium"
                            >
                              {product.name}
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm transition-all link font-medium leading-6 text-gray-950"
                >
                  {item.name}
                </Link>
              )
            )}
            <div className="flex space-x-5 items-center">
              <div className="bg-white hover:bg-orange-400 border border-gray-200 hover:border-orange-400 text-gray-800 text-sm px-2 py-[8px] rounded-lg shadow-lg hover:translate-y-[-2px] transition-all ease-linear duration-300">
                <Link to="/products">
                  <img
                    src="https://content.qeeb.in/coltec/products/shop-icon.svg"
                    alt="cart-icon"
                    title="Shop Now"
                    className="h-[18px] invert"
                  />
                </Link>
              </div>
              {/* Conditional Rendering Starts Here */}
              {user ? (
                <div className="relative">
                  {user.loginMethod === "google" ? (
                    // Google User Dropdown
                    <button
                      className="flex items-center space-x-2 text-sm rounded-full transition-all ease-linear duration-300"
                      onClick={() => setAvatarDropdownOpen(!avatarDropdownOpen)}
                    >
                      <img
                        src={user.avatar}
                        alt={user.name}
                        className="h-8 w-8 rounded-full"
                      />
                    </button>
                  ) : (
                    // Manual Login User Icon
                    <button
                      className="flex items-center space-x-2 rounded-full transition-all ease-linear duration-300"
                      onClick={() => setAvatarDropdownOpen(!avatarDropdownOpen)}
                    >
                      <img
                        src="https://content.qeeb.in/coltec/default-avatar.png"
                        alt={user.userName}
                        className="h-8 w-8 rounded-full"
                      />
                    </button>
                  )}
                  {/* Dropdown Menu */}
                  {avatarDropdownOpen && (
                    <div className="absolute right-0 mt-4 border border-gray-200 w-40 bg-white rounded-md shadow-lg p-3 z-20">
                      <Link to="/products/cart" className="flex text-sm font-medium mb-3 group">
                        <LuShoppingCart className="text-lg mr-2" />
                        <span className="group-hover:text-blue-500">View cart</span>
                      </Link>
                      <button
                        onClick={() => {
                          handleLogout();
                          setAvatarDropdownOpen(false);
                        }}
                        className="flex items-center text-left text-sm font-medium text-gray-800 hover:text-red-600"
                      >
                        <img
                          src="https://content.qeeb.in/coltec/products/logout-icon.svg"
                          alt="Logout"
                          className="h-[14px] mr-2"
                        />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    openSignInModal();
                    setIsSignUp(false); // Ensure modal starts with Sign-In view
                    setCurrentStep(1); // Reset to Step 1 when opening
                    setFormData({
                      name: "",
                      email: "",
                      mobileNumber: "",
                      password: "",
                      originationName: "",
                      location: "",
                    }); // Reset form data
                  }}
                  className="bg-white hover:bg-orange-400 border border-gray-200 hover:border-orange-400 text-gray-800 text-sm px-5 py-[8px] rounded-lg shadow-lg transition-all ease-linear duration-300"
                >
                  Sign in
                </button>
              )}
            </div>
          </div>
        </nav>

        {/* Mobile Menu */}
        <Dialog
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <img
                  alt="Logo"
                  src={
                    isFixed
                      ? "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                      : "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                  }
                  className="h-7 w-auto"
                />
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-8 w-8" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="flex flex-col py-6 space-y-2">
                  {navigation.map((item) =>
                    item.name === "Solutions" ? (
                      <div key={item.name} className="relative">
                        <button
                          onClick={() => handleMenuClick("mobile-solutions")}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                        >
                          {item.name}
                        </button>
                        {openMenu === "mobile-solutions" && (
                          <div className="mt-2 space-y-2">
                            {solutionsDropdown.map((dropdownItem) => (
                              <Link
                                key={dropdownItem.name}
                                to={dropdownItem.href}
                                className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                              >
                                {dropdownItem.name}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : item.name === "Products" ? (
                      <div key={item.name} className="relative">
                        <button
                          onClick={() => handleMenuClick("mobile-products")}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                        >
                          {item.name}
                        </button>
                        {openMenu === "mobile-products" && (
                          <div className="mt-2 space-y-2">
                            {productsDropdown.map((product) =>
                              product.name === "Interactive Flat Panel" ? (
                                <div key={product.name} className="relative">
                                  <button
                                    onClick={handleInteractiveClick}
                                    className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                                  >
                                    {product.name}
                                  </button>
                                  {interactiveDropdownOpen && (
                                    <div className="ml-4 space-y-2">
                                      {interactiveFlatPanelDropdown.map(
                                        (item) => (
                                          <Link
                                            key={item.name}
                                            to={item.href}
                                            className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                                          >
                                            {item.name}
                                          </Link>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <Link
                                  key={product.name}
                                  to={product.href}
                                  className="block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                                >
                                  {product.name}
                                </Link>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-black"
                      >
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      {/* Sign-In / Sign-Up Modal */}
      {isSignInModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 py-6">
            <div
              className="fixed inset-0 transition-opacity"
              onClick={() => {
                closeSignInModal();
                setIsSignUp(false);
                setCurrentStep(1);
                setFormData({
                  userName: "",
                  email: "",
                  phoneNo: "",
                  password: "",
                  organizationName: "",
                  address: "",
                });
              }}
            >
              <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>
            <div
              className={`bg-white animate-zoom-in rounded-2xl overflow-hidden shadow-xl transform transition-all md:max-w-[750px] w-full z-10`}
            >
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-0 relative">
                {/* Right Side Image (only for Sign-In and Step 2 of Sign-Up) */}
                {!isSignUp ? (
                  <div className="relative p-7 hidden lg:block bg-gradient-to-r from-[#d2e7fa] to-[#f8fbfe] rounded-tr-[20px] rounded-br-[20px]">
                    <div className="flex flex-col justify-center items-center h-full">
                      <img
                        alt="LoginImage"
                        src="https://content.qeeb.in/coltec/signup-img.png"
                        className={`rounded-r-xl w-full ${
                          currentStep === 2 ? "object-cover" : "object-cover"
                        }`}
                      />
                      <p className="mt-8 mx-auto text-center text-[#4282ff] text-[15px] font-semibold font-['Poppins'] leading-[1.5]">
                        Sign up to get the latest insights, industry updates,
                        and exclusive offers. Be the first to know what’s
                        shaping the future!
                      </p>
                    </div>
                  </div>
                ) : null}
                {/* Left Side Form */}
                <div className="md:p-8 p-6">
                  <div className="absolute right-5 z-[1] top-5">
                    <button
                      onClick={() => {
                        closeSignInModal();
                        setIsSignUp(false);
                        setCurrentStep(1);
                        setFormData({
                          userName: "",
                          email: "",
                          phoneNo: "",
                          password: "",
                          organizationName: "",
                          address: "",
                        });
                      }}
                    >
                      <img
                        alt="close-icon"
                        className="h-5"
                        src="https://content.qeeb.in/coltec/close-icon.svg"
                      />
                    </button>
                  </div>
                  <div className="mx-auto w-full">
                    {!isSignUp ? (
                      // Sign-In View
                      <div>
                        <div className="mt-4">
                          <p className="text-center text-[#b2b1b1] text-xs font-normal leading-[14.40px]">
                            Sign-in with your Google account
                          </p>
                          <div className="mt-4 mb-6 grid grid-cols-1 gap-4 google-button">
                            {/* Google Login Button */}
                            <GoogleLogin
                              onSuccess={handleGoogleSuccess}
                              onError={handleGoogleError}
                              useOneTap
                              theme="filled_blue"
                              size="large"
                            />
                          </div>
                          <p className="text-center text-[#bababa] text-xs font-normal leading-[14.40px]">
                            Or use your email to sign-in
                          </p>
                          <form className="mt-5" onSubmit={handleSignInSubmit}>
                            <div className="space-y-4 grid grid-cols-1">
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-full h-10 text-sm bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                required
                              />
                              <div className="relative">
                                <input
                                  type={isUserPassword ? "text" : "password"} // Toggle type
                                  name="password"
                                  placeholder="Password"
                                  value={formData.password}
                                  onChange={handleInputChange}
                                  className="w-full text-sm h-10 bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                  required
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    setUserPassword(!isUserPassword)
                                  } // Toggle visibility
                                  className="absolute bottom-2.5 right-4 flex items-center text-[#8A9CB5] hover:text-[#8A9CB5]/95"
                                >
                                  {isUserPassword ? (
                                    <AiOutlineEyeInvisible
                                      size={20}
                                      className=""
                                    />
                                  ) : (
                                    <AiOutlineEye size={20} />
                                  )}
                                </button>
                              </div>
                              <p className="text-[#232323] text-[11px] font-normal">
                                By continuing, you agree to COLTEC's{" "}
                                <Link
                                  to="/privacy-policy"
                                  className="text-[#4282ff]"
                                >
                                  Privacy Policy
                                </Link>{" "}
                                and{" "}
                                <Link
                                  to="/cookies-policy"
                                  className="text-[#4282ff]"
                                >
                                  Cookies Policy
                                </Link>
                                .
                              </p>
                              <div className="text-center">
                                <button className="bg-[#4282ff] text-white py-2 px-8 rounded-full">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                          <div className="mt-3 text-center">
                            <p className="text-[11px] text-gray-600">
                              Don't have an account?{" "}
                              <button
                                onClick={() => {
                                  setIsSignUp(true);
                                  setCurrentStep(1); // Start at Step 1
                                  setFormData({
                                    userName: "",
                                    email: "",
                                    phoneNo: "",
                                    password: "",
                                    organizationName: "",
                                    address: "",
                                  }); // Reset form data
                                }}
                                className="text-blue-600 hover:underline"
                              >
                                Sign Up
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // Sign-Up View (Multi-Step)
                      <div>
                        <div>
                          <h2 className="text-center text-[#26baff] text-[32px] font-semibold leading-[38.40px] mb-5">
                            Sign-up
                          </h2>
                          <form className="mt-5" onSubmit={handleSignUpSubmit}>
                            {currentStep === 1 && (
                              <div className="grid grid-cols-1 gap-4">
                                <input
                                  type="text"
                                  name="userName" // Changed from "username" to "userName"
                                  placeholder="Name"
                                  value={formData.userName}
                                  onChange={handleInputChange}
                                  className="w-full text-sm h-10 bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                  required
                                />
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  className="w-full text-sm h-10 bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                  required
                                />
                                <input
                                  type="tel"
                                  name="mobileNumber"
                                  placeholder="Mobile Number"
                                  value={formData.phoneNo}
                                  onChange={(e) => {
                                    // Only allow numeric values
                                    const value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    setFormData({
                                      ...formData,
                                      phoneNo: value,
                                    });
                                  }}
                                  inputMode="numeric" // Helps with mobile numeric keypad
                                  pattern="[0-9]*" // Pattern to match only numeric input (supports HTML5 validation)
                                  className="w-full text-sm h-10 bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                  required
                                />
                                <div className="relative">
                                  <input
                                    type={isUserPassword ? "text" : "password"} // Toggle type
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    className="w-full text-sm h-10 bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                    required
                                  />
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setUserPassword(!isUserPassword)
                                    } // Toggle visibility
                                    className="absolute bottom-2.5 right-4 flex items-center text-[#8A9CB5] hover:text-[#8A9CB5]/95"
                                  >
                                    {isUserPassword ? (
                                      <AiOutlineEyeInvisible
                                        size={20}
                                        className=""
                                      />
                                    ) : (
                                      <AiOutlineEye size={20} />
                                    )}
                                  </button>
                                </div>
                              </div>
                            )}
                            {currentStep === 2 && (
                              <div className="grid grid-cols-1 mt-8 gap-4">
                                <input
                                  type="text"
                                  name="organizationName"
                                  placeholder="Organization Name"
                                  value={formData.organizationName}
                                  onChange={handleInputChange}
                                  className="w-full text-sm h-10 bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                  required
                                />
                                <input
                                  type="text"
                                  name="address"
                                  placeholder="Location"
                                  value={formData.address}
                                  onChange={handleInputChange}
                                  className="w-full text-sm h-10 bg-[#efefef] rounded-lg border-0 placeholder:text-sm"
                                  required
                                />
                              </div>
                            )}
                            <div className="flex justify-between mt-8">
                              {currentStep === 2 && (
                                <button
                                  type="button"
                                  onClick={() => setCurrentStep(1)}
                                  className="bg-gray-300 text-gray-700 px-3 py-2 mr-4 rounded-md hover:bg-gray-400 transition-colors"
                                >
                                  <img
                                    alt="Arrow Black"
                                    src="https://content.qeeb.in/coltec/arrow-black.svg"
                                    className="w-5"
                                  />
                                </button>
                              )}
                              <button
                                type="submit"
                                className={`${
                                  currentStep === 1
                                    ? "bg-blue-600 hover:bg-blue-700"
                                    : "bg-blue-600 hover:bg-blue-700"
                                } text-white px-4 py-2 rounded-full w-full transition-colors`}
                              >
                                {currentStep === 1 ? "Submit" : "Sign Up"}
                              </button>
                            </div>
                          </form>
                          <div className="google-sign-up">
                            <p className="text-center my-4 text-[#bababa] text-xs font-normal leading-[14.40px]">
                              OR
                            </p>
                            <GoogleLogin
                              onSuccess={handleGoogleSuccess}
                              onError={handleGoogleError}
                              useOneTap
                              theme="filled_blue"
                              size="large"
                            />
                          </div>
                          <div className="mt-4 text-center">
                            <p className="text-xs text-gray-600">
                              Already have an account?{" "}
                              <button
                                onClick={() => {
                                  setIsSignUp(false);
                                  setCurrentStep(1);
                                  setFormData({
                                    userName: "",
                                    email: "",
                                    phoneNo: "",
                                    password: "",
                                    organizationName: "",
                                    address: "",
                                  });
                                }}
                                className="text-blue-600 hover:underline"
                              >
                                Sign In
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Optionally, include the right-side image only for Sign-In or specific steps */}
                {isSignUp ? (
                  <div className="relative p-8 hidden lg:block bg-gradient-to-r from-[#d2e7fa] to-[#f8fbfe] rounded-tr-[20px] rounded-br-[20px]">
                    <div className="flex flex-col justify-center items-center h-full">
                      <img
                        alt="LoginImage"
                        src="https://content.qeeb.in/coltec/signup-img.png"
                        className={`rounded-r-xl w-full ${
                          currentStep === 1 ? "object-cover" : "object-none"
                        }`}
                      />
                      <p className="w-[290px] mt-8 mx-auto text-center text-[#4282ff] text-lg font-semibold font-['Poppins'] leading-tight">
                        Stay Ahead! Be the First to Know About Our Game-Changing
                        Products.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}

      {toast.showToast && (
        <div
          className={`fixed top-4 right-4 p-4 z-50 rounded shadow w-[250px] ${
            toast.type === "success"
              ? "bg-green-50 text-green-600"
              : "bg-red-50 text-red-600"
          }`}
        >
          <div className="flex">{toast.message}</div>
        </div>
      )}
    </>
  );
};

export default Header;
