export const addBlogComment = async (blogId, username, comment) => {
    const url = 'https://devapi.qeeb.in/api/Coltec/AddBlogComment';
  
    // Create the payload as JSON
    const payload = {
      blogId,
      username,
      comment,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json', // Ensure the server knows the client expects JSON
          'Content-Type': 'application/json', // Specify the payload format
        },
        body: JSON.stringify(payload), // Convert payload to JSON string
      });
  
      if (!response.ok) {
        // Attempt to parse error message from response
        let errorMessage = 'Failed to post comment';
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || errorMessage;
        } catch (parseError) {
          // If response is not JSON, retain default error message
        }
        throw new Error(errorMessage);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error posting comment:', error);
      throw error;
    }
  };