import React, { useRef, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { products } from "../../utils/productsData";
import SEO from "../../components/SEO";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ScrollToTopButton from "../../components/ScrollTop";
import WhatsAppMsg from "../../components/WhatsApp";
import FAQ from "../../components/FAQ";
import { faqData } from "../../utils/productFAQ";
import Testimonials from "../../components/Testimonials";
import { UserContext } from "../../utils/UserContext";
import { Link } from "react-router-dom";

const ProductDetails = () => {
  const { slug } = useParams();
  // Consume UserContext
  const { user, openSignInModal } = useContext(UserContext);
  const navigate = useNavigate();
  // Refs for the central images
  const imagesRef = useRef([]);
  // Local state for quantity
  const [quantity, setQuantity] = useState(1);
  const [toast, setToast] = useState({
    showToast: false,
    message: "",
    type: "",
  });

  // This state will track if product is already added to cart
  const [addedToCart, setAddedToCart] = useState(false);

  // Flatten all products into an array
  const allProducts = Object.values(products).flat();

  // Find the product by the current URL param `slug`
  const product = allProducts.find((p) => p.slug === slug);

  // If no product found, show 404-like message
  if (!product) {
    return <div>Product not found!</div>;
  }

  // Extract brand from product name, e.g. "COLTEC Rise"
  const brandPart = product.name.split("|")[0].trim();

  // All products that belong to the same brand
  const sameBrandProducts = allProducts.filter((p) =>
    p.name.includes(brandPart)
  );

  // Parse out the size portion of the current product’s name, e.g. "65” Size"
  const currentSize = product.name.split("|")[1]?.trim() || "";

  // Gather all sizes for the brand
  const allSizesForBrand = sameBrandProducts.map((p) =>
    p.name.split("|")[1].trim()
  );

  // When the user picks a new size from the dropdown,
  // navigate to the matching product’s route
  const handleSizeChange = (e) => {
    const newSize = e.target.value; // e.g. "75” Size"
    const newProduct = sameBrandProducts.find((p) => p.name.includes(newSize));
    if (newProduct) {
      // Push new route => changes slug => re-renders with new product
      navigate(`/product-details/${newProduct.slug}`);
    }
  };

  // Scroll into the corresponding full image when thumbnail is clicked
  const handleThumbnailClick = (index) => {
    if (imagesRef.current[index]) {
      imagesRef.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  // Increment/Decrement quantity, min 1
  const decreaseQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };
  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  // 1. Handle "Add to Cart"
  const handleAddToCart = async () => {
    if (!user) {
      // User not logged in
      openSignInModal();
      return;
    }
    // Convert the price from string to integer
    const priceAsInt = parseInt(product.price.replace(/[^0-9]/g, ""), 10);
    // Build the form data
    const formData = {
      name: product.name,
      imageUrl:
        product.gallery && product.gallery.length > 0 ? product.gallery[0] : "",
      quantity,
      panelSize: currentSize,
      price: priceAsInt,
      email: user.email, // user from context
    };

    try {
      const response = await fetch(
        "https://devapi.qeeb.in/api/Coltec/AddCartProduct",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add to cart");
      }

      // Switch button to "Continue Shopping" mode
      setAddedToCart(true);

      // Show success toast
      setToast({
        showToast: true,
        message: "Product added to cart!",
        type: "success",
      });
      setTimeout(() => {
        setToast({ showToast: false, message: "", type: "" });
      }, 2000);
    } catch (error) {
      console.error("Error adding to cart:", error);
      // Show an error toast
      setToast({
        showToast: true,
        message: "Failed to add product to cart. Please try again.",
        type: "error",
      });
      setTimeout(() => {
        setToast({ showToast: false, message: "", type: "" });
      }, 2000);
    }
  };

  // 2. Handle "View Cart" -> GET cart data -> navigate to /cart
  const handleViewCart = async () => {
    if (!user) {
      openSignInModal();
      return;
    }
  
    // Dynamically get the user's email from the user context
    const userEmail = user.email;
  
    try {
      const response = await fetch(`https://devapi.qeeb.in/api/Coltec/GetCartDetailsByEmail?Email=${encodeURIComponent(userEmail)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Add any necessary authentication or headers if needed
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch cart data");
      }
      // Do something with cartData (e.g., update global state or navigate to the cart page)
  
      // Navigate to /cart
      navigate("/products/cart");
    } catch (error) {
      console.error("Error fetching cart data:", error);
      setToast({
        showToast: true,
        message: "Failed to fetch cart data.",
        type: "error",
      });
      setTimeout(() => {
        setToast({ showToast: false, message: "", type: "" });
      }, 2000);
    }
  };
  

  const testimonials = [
    {
      image:
        "https://content.qeeb.in/coltec/testimonials/global-sainik-academy.jpg",
      name: "Sharan Sikenpore",
      altImage: "Coltec Customer Global Sainik Academy",
      designation: "Founder Chairman & CEO",
      description:
        "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/shaheen-group.jpg",
      name: "Mr Qursheed Ahmed",
      altImage: "Coltec Customer Shaheen Group",
      designation: "Academic Councellor",
      description:
        "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/gaudium-logo.png",
      name: "Sudeshna Chatterjee",
      altImage: "Coltec Customer Gaudium School",
      designation: "Head of School",
      description:
        "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/iit-madras-logo.png",
      name: "Vikas Sitraman",
      altImage: "Coltec Customer IIT Madras",
      designation: "IIT Madras - Academic Councellor",
      description:
        "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/berkadia.jpg",
      name: "Raj Kumar",
      altImage: "Coltec Customer Berkadia",
      designation: "AV Head",
      description:
        "“COLTEC’s interactive displays are a cornerstone of our new training facilities. The high-quality visuals and intuitive touch controls have enhanced our employee training programs significantly. We are very pleased with the results.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/spring-filed.jpg",
      name: "Akash Awasti",
      altImage: "Coltec Customer Spring Filed",
      designation: "Vice Principal",
      description:
        "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/edify-education.png",
      name: "Kishore Pandit",
      altImage: "Coltec Customer Edify Education",
      designation: "Head of School",
      description:
        "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
    },
    {
      image:
        "https://content.qeeb.in/coltec/testimonials/the-dollar-business.png",
      name: "Ashok Tripathi",
      altImage: "Coltec Customer The Dollar Business",
      designation: "IT Head",
      description:
        "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
    },
  ];

  return (
    <>
      {/* SEO */}
      <div className="blogs-page">
        <SEO
          title={`${product.title} at ${product.price}`}
          description={product.metaDescription}
          keywords={product.keywords}
          ogTitle={product.name}
          ogDescription={product.metaDescription}
          ogImage={product.imageUrl}
          ogUrl={`https://coltec-global.com/product-details/${product.slug}`}
          canonicalUrl={`https://coltec-global.com/product-details/${product.slug}`}
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <div className="about-us-page pt-14">
          <Header />
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <div className="grid grid-cols-12 md:gap-5 gap-3">
              {/* Left Column (Thumbnails) */}
              <div className="md:col-span-1 col-span-3 hidden md:block">
                <div className="sticky top-20">
                  <div className="flex flex-col gap-3">
                    {product.gallery.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className="cursor-pointer w-20 h-20 object-cover rounded-md"
                        onClick={() => handleThumbnailClick(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>

              {/* Center Column (Full Images) */}
              <div className="md:col-span-6 col-span-full">
                <div className="min-h-screen space-y-5">
                  {product.gallery.map((image, index) => (
                    <div
                      key={index}
                      ref={(el) => (imagesRef.current[index] = el)}
                      className=""
                    >
                      <img
                        src={image}
                        alt={`Fullimage ${index + 1}`}
                        className="object-contain w-full rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Right Column (Product Info) */}
              <div className="md:col-span-5 col-span-full">
                <div className="sticky top-20">
                  <h1 className="text-2xl font-medium mb-2">{product.name}</h1>

                  <p className="price text-lg font-semibold text-black">
                    ₹{product.price}
                    <span className="line-through ml-2 font-medium text-[#797979]">
                      ₹{product.originalPrice}
                    </span>
                  </p>

                  {/* Description */}
                  <div className="product-description text-sm text-[#797979] mt-4">
                    <p>{product.description}</p>
                  </div>

                  {/* Panel Size Dropdown */}
                  <div className="my-4">
                    <label
                      htmlFor="panelSize"
                      className="block mb-2 font-normal text-sm"
                    >
                      Panel size required
                    </label>
                    <select
                      id="panelSize"
                      className="border w-full border-gray-300 rounded p-2"
                      onChange={handleSizeChange}
                      value={currentSize}
                    >
                      {allSizesForBrand.map((sz) => (
                        <option key={sz} value={sz}>
                          {sz}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Quantity Selector */}
                  <div className="my-4 flex justify-between">
                    <label className="block mb-2 font-normal text-sm">
                      Quantity required
                    </label>
                    <div className="flex items-center space-x-3 border rounded">
                      <button
                        type="button"
                        onClick={decreaseQuantity}
                        className="px-3 py-1 bg-white border-r rounded-l text-[#4282ff]"
                      >
                        –
                      </button>
                      <span>{quantity}</span>
                      <button
                        type="button"
                        onClick={increaseQuantity}
                        className="px-3 py-1 bg-white border-l rounded-r text-[#4282ff]"
                      >
                        +
                      </button>
                    </div>
                  </div>

                  {/* Conditionally Render the Button */}
                  <div className="flex space-x-4">
                  {!addedToCart ? (
                    <button
                      onClick={handleAddToCart}
                      className="bg-[#4282ff] w-full text-white py-2.5 font-semibold hover:bg-[#4282ff]/90 px-4 rounded-xl"
                    >
                      Add to Cart
                    </button>
                  ) : (
                    <button
                      onClick={handleViewCart}
                      className="bg-black w-full text-white py-2.5 font-semibold hover:bg-gray-800 px-4 rounded-xl"
                    >
                      View Cart
                    </button>
                  )}
                  <Link
                      to="/products"
                      className="hover:bg-orange-500 w-full text-center text-white py-2.5 font-semibold bg-orange-400 px-4 rounded-xl"
                    >
                      Continue Shopping
                    </Link>
                  </div>

                  {/* Specs */}
                  {product.specs && product.specs.length > 0 && (
                    <div className="product-specifications mt-6">
                      <h2 className="text-2xl font-semibold mb-5">
                        Technical Details
                      </h2>
                      <div className="border-t">
                        {product.specs.map((spec, index) => (
                          <div
                            key={index}
                            className="flex justify-between md:px-5 items-center mb-2 border-b py-3"
                          >
                            <span className="font-medium text-[12px] w-1/2">
                              {spec.label}:
                            </span>
                            <span className="text-[12px] w-1/2 text-left">
                              {spec.value}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#f5f5f5]">
            <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
              <Testimonials testimonials={testimonials} />
            </div>
          </div>
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <FAQ data={faqData} />
          </div>
          <Footer />
          <ScrollToTopButton />
          <WhatsAppMsg />
        </div>
      </div>
      {/* Toast Notifications */}
      {toast.showToast && (
        <div
          className={`fixed top-4 right-4 p-4 z-50 rounded shadow ${
            toast.type === "success"
              ? "bg-green-50 text-green-600"
              : "bg-red-50 text-red-600"
          }`}
        >
          <div className="flex">{toast.message}</div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
