export const faqData = [
  {
    question: "What is COLTEC Interactive Flat Panel?",
    answer:
      "COLTEC Interactive Flat Panel is a high-quality touchscreen display designed for both educational and corporate settings. It offers a seamless, interactive experience with advanced features and a sleek design, available in various sizes.",
  },
  {
    question: "How do I select a product on the website?",
    answer:
      "Simply navigate through the categories on the left sidebar, such as 'Interactive Flat Panel,' 'Room Scheduler,' and more. Click on the product you'd like to view for more details, including specifications and pricing.",
  },
  {
    question:
      "What is the difference between COLTEC Rise, COLTEC Evoke, and COLTEC Inspire?",
    answer:
      "Each of these models offers different features and aesthetics: COLTEC Rise: High-end design with cutting-edge performance. COLTEC Evoke: Focuses on performance and ease of use. COLTEC Inspire: Offers a sleek, minimalistic design with innovative features.",
  },
  {
    question: "How do I contact customer support?",
    answer:
      "You can contact our customer support via the 'Chat with us' feature at the bottom right of the page, where our team is ready to assist you.",
  },
  {
    question:
      "What sizes are available for the COLTEC Interactive Flat Panels?",
    answer:
      "Currently, we offer the 65-inch,75-inch,86-inch and 98-inch size for all models: COLTEC Rise, COLTEC Evoke, and COLTEC Inspire.",
  },
  {
    question: "Do COLTEC products come with a warranty?",
    answer:
      "Yes, COLTEC products come with a warranty. For detailed warranty information, please check the product description or contact customer support.",
  },
  {
    question:
      "Can I get more information about each product before purchasing?",
    answer:
      "Absolutely! Click on each product image for a detailed description, including specifications, features, and pricing. You can also reach out to our support team for more information.",
  },
];
