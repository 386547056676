import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import CTA from "../../components/CTA";
import OurClients from "../../components/OurClients";
import CorporateModule from "../../components/CorporateSwiper";
import ScrollToTopButton from "../../components/ScrollTop";
import SEO from "../../components/SEO";
import Testimonials from "../../components/Testimonials";
import WhatsAppMsg from "../../components/WhatsApp";
import FAQ from "../../components/FAQ";
import { faqData } from "../../utils/corporateFAQ";

const testimonials = [
  {
    image:
      "https://content.qeeb.in/coltec/testimonials/global-sainik-academy.jpg",
    name: "Sharan Sikenpore",
    altImage: "Coltec Customer Global Sainik Academy",
    designation: "Founder Chairman & CEO",
    description:
      "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/shaheen-group.jpg",
    name: "Mr Qursheed Ahmed",
    altImage: "Coltec Customer Shaheen Group",
    designation: "Academic Councellor",
    description:
      "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/gaudium-logo.png",
    name: "Sudeshna Chatterjee",
    altImage: "Coltec Customer Gaudium School",
    designation: "Head of School",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/iit-madras-logo.png",
    name: "Vikas Sitraman",
    altImage: "Coltec Customer IIT Madras",
    designation: "IIT Madras - Academic Councellor",
    description:
      "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/berkadia.jpg",
    name: "Raj Kumar",
    altImage: "Coltec Customer Berkadia",
    designation: "AV Head",
    description:
      "“COLTEC’s interactive displays are a cornerstone of our new training facilities. The high-quality visuals and intuitive touch controls have enhanced our employee training programs significantly. We are very pleased with the results.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/spring-filed.jpg",
    name: "Akash Awasti",
    altImage: "Coltec Customer Spring Filed",
    designation: "Vice Principal",
    description:
      "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/edify-education.png",
    name: "Kishore Pandit",
    altImage: "Coltec Customer Edify Education",
    designation: "Head of School",
    description:
      "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
  },
  {
    image:
      "https://content.qeeb.in/coltec/testimonials/the-dollar-business.png",
    name: "Ashok Tripathi",
    altImage: "Coltec Customer The Dollar Business",
    designation: "IT Head",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
];

const cslides = [
  {
    heading: "Writing Board",
    description:
      "Our cutting-edge writing board offers unparalleled innovation with its seamless, endless surface. Designed for modern boardrooms, it enhances interaction, fosters creativity, and supports dynamic presentation methods, making meetings more engaging and effective.",
    image: "https://content.qeeb.in/coltec/corporate/whiteboard.jpg",
    altTag:
      "Coltec Interactive Flat Panel displaying a SWOT analysis in a modern meeting room, enhancing collaborative brainstorming and strategic planning sessions.",
  },
  {
    heading: "Presentation",
    description: `Deliver impactful corporate presentations with our state-of-the-art technology. Enhance your meetings with seamless integration, clear visuals, and interactive features designed to engage your audience and convey your message effectively.
Share content from any device wirelessly. Multi-share upto 4 devices simultaneously.`,
    image: "https://content.qeeb.in/coltec/corporate/presentation.jpg",
    altTag:
      "Business presentation using a Coltec Interactive Flat Panel displaying financial analytics and stock market charts in a corporate boardroom for data-driven decision-making.",
  },
  {
    heading: "Share & Compare",
    description: `Effortlessly share and compare ideas, documents, and data with our intuitive tools. Enhance collaboration, streamline discussions, and improve decision-making in any setting with our user-friendly features.`,
    image: "https://content.qeeb.in/coltec/corporate/share-compare.jpg",
    altTag:
      "Coltec Interactive Flat Panel displaying bar and line charts in a modern meeting room, enhancing collaborative data analysis and business presentations.",
  },
];
const conslides = [
  {
    heading: "Teams",
    description:
      "Optimize your office meetings with our advanced tools for team collaboration. Facilitate seamless communication, share documents in real-time, and enhance productivity, ensuring every team meeting is efficient and effective.",
    image: "https://content.qeeb.in/coltec/corporate/office-teams-meet.jpg",
    altTag:
      "Coltec Interactive Flat Panel facilitating a virtual meeting with video conferencing and collaborative presentation tools in a modern conference room.",
  },
  {
    heading: "Zoom",
    description: `Enhance your Zoom meetings with our integrated solutions. Experience seamless connectivity, clear audio and video, and interactive features that make every meeting productive and engaging. Perfect for team collaboration, training sessions, and virtual events.`,
    image: "https://content.qeeb.in/coltec/corporate/zoom-meet.jpg",
    altTag:
      "Coltec Interactive Flat Panel hosting a Zoom meeting in a modern conference room, offering seamless connectivity, clear audio, and video for collaborative virtual sessions.",
  },
  {
    heading: "Whiteboarding in Video Conferencing",
    description: `Enhance your video conferences with our whiteboarding feature. Collaborate in real-time, visualize ideas, and brainstorm effectively with interactive whiteboards. Perfect for team discussions, training sessions, and creative meetings, making remote collaboration seamless and productive.`,
    image: "https://content.qeeb.in/coltec/corporate/whiteboarding-vc-call.jpg",
    altTag:
      "Coltec Interactive Flat Panel facilitating whiteboarding in video conferencing, enabling real-time collaboration, brainstorming, and idea visualization for productive team meetings.",
  },
];
const tslides = [
  {
    heading: "Presentation",
    description:
      "Deliver powerful presentations with our advanced technology. Engage your audience with stunning visuals, interactive features, and seamless transitions. Perfect for business meetings, educational settings, and conferences, ensuring your message is clear, impactful, and memorable.",
    image: "https://content.qeeb.in/coltec/corporate/training-presentation.jpg",
    altTag:
      "Coltec Interactive Flat Panel enhancing business presentations with interactive visuals and real-time data sharing in a modern training room.",
  },
  {
    heading: "Whiteboarding - Collab",
    description: `Enhance collaboration with our whiteboarding tools. Work together in real-time, share ideas visually, and brainstorm seamlessly. Ideal for team meetings, creative sessions, and problem-solving, our whiteboarding feature fosters effective and dynamic collaboration.`,
    image: "https://content.qeeb.in/coltec/corporate/training-whiteboard.jpg",
    altTag:
      "Interactive whiteboard in a training room showcasing brainstorming and real-time collaboration features for effective team discussions and creative sessions.",
  },
  {
    heading: "Video Conference",
    description: `Elevate your training sessions with our video conference technology. Deliver high-quality, interactive training to remote teams, ensuring clear communication, real-time feedback, and an engaging learning experience. Enhance productivity and learning outcomes with our reliable and user-friendly platform.`,
    image: "https://content.qeeb.in/coltec/corporate/training-vc.jpg",
    altTag:
      "Coltec Interactive flat panel in a professional training room displaying a video conference session with multiple participants, enabling seamless communication and interactive learning.",
  },
];
const Corporate = () => {
  return (
    <>
      <SEO
        title="Corporate Interactive Flat Panels"
        description="Empower your teams with Coltec’s interactive flat panels—enhance meetings, streamline decision-making, and transform your corporate culture."
        keywords="Interactive Flat Panel, Smart Classroom Display, Touch Screen Panel for Education, Digital Whiteboard, Interactive Display for Schools, 4K Ultra HD Interactive Panel, Interactive Touch Screen for Business, Education Technology Solutions, Interactive Flat Panel Display, Smart Board for Classrooms, Interactive flat panels, Interactive displays, Touchscreen displays, Digital whiteboards, Corporate collaboration technology, Interactive meeting room solutions, Boardroom touchscreen displays, Wireless presentation systems, Engaging workplace communication tools"
        ogTitle="Corporate Interactive Flat Panels | Coltec Solutions"
        ogDescription="Empower your teams with Coltec’s interactive flat panels—enhance meetings, streamline decision-making, and transform your corporate culture."
        ogImage="https://content.qeeb.in/coltec/coltec-logo-black.svg"
        ogUrl="https://coltec-global.com/solutions/corporate"
        canonicalUrl="https://coltec-global.com/solutions/corporate"
        ogImageAlt="Corporate Interactive Flat Panels"
        ogSiteName="Coltec Global"
        ogType="website"
      />
      <div className="bg-gray-900">
        <Header />
        <Banner
          imgSrc="https://content.qeeb.in/coltec/corporate/banner-img.jpg"
          altText="banner-img"
          bannerHeight="md:h-dvh h-[60vh]"
          heading="Collaborate | Conference & Train Effortlessly"
          opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-neutral-liner z-0"
          paragraphText="Enhance Team’s Productivity with COLTEC’s State-of-the-art Interactive Visual Technology Solutions. Designed for modern meeting &training spaces."
          positionClasses="absolute md:bottom-64 bottom-20 left-0 lg:left-32"
          headClasses="text-[29px] md:text-4xl max-w-4xl text-white font-medium leading-10"
          paragraphTextClasses="text-base text-white max-w-lg mt-4"
        />
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
          <h2 className="md:text-4xl text-[28px] font-normal mb-4 lg:text-center">
            Collaboration
          </h2>
          <CorporateModule slides={cslides} />
        </div>
      </div>
      <div className="bg-[#DADADA]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
          <h2 className="md:text-4xl text-[28px] font-normal mb-4 lg:text-center">
            Conferencing
          </h2>
          <CorporateModule slides={conslides} />
        </div>
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
          <h2 className="md:text-4xl text-[28px] font-normal mb-4 lg:text-center">
            Training
          </h2>
          <CorporateModule slides={tslides} />
        </div>
      </div>
      <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
        <FAQ data={faqData} />
      </div>
      <div className="bg-[#f5f5f5]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
          <Testimonials testimonials={testimonials} />
        </div>
      </div>

      <div className="bg-gray-50">
        <OurClients />
      </div>
      <CTA />
      <Footer />
      <ScrollToTopButton />
      <WhatsAppMsg />
    </>
  );
};

export default Corporate;
