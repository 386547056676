export const faqData = [
    {
      question: "How can Coltec's solutions improve corporate meetings?",
      answer: "Coltec's interactive flat panels enhance corporate meetings by enabling seamless collaboration, real-time annotations, and easy content sharing, making discussions more productive and engaging."
    },
    {
      question: "What integration options are available for Coltec's corporate solutions?",
      answer: "Coltec's corporate solutions integrate effortlessly with popular business tools like Microsoft Office, Google Workspace, and various video conferencing platforms, ensuring smooth workflow and connectivity."
    },
    {
      question: "Are Coltec's solutions compatible with existing corporate IT infrastructure?",
      answer: "Yes, Coltec's solutions are designed to seamlessly integrate with existing IT infrastructures, supporting various operating systems, network configurations, and security protocols used in corporate environments."
    },
    {
      question: "What security features do Coltec's corporate solutions offer?",
      answer: "Coltec ensures robust security with features like encrypted data transmission, secure user authentication, and compliance with industry standards to protect sensitive corporate information."
    },
    {
      question: "How do Coltec's solutions support remote and hybrid work environments?",
      answer: "Coltec's interactive panels facilitate remote and hybrid work by providing seamless video conferencing integration, wireless connectivity, and collaborative tools that bridge the gap between in-office and remote teams."
    },
    {
      question: "What training and support does Coltec provide for corporate clients?",
      answer: "Coltec offers comprehensive training programs, including on-site workshops and online tutorials, along with dedicated technical support to ensure corporate clients can effectively utilize their interactive solutions."
    },
    {
      question: "Can Coltec's solutions be customized to fit specific corporate needs?",
      answer: "Absolutely. Coltec provides customizable software and hardware options, allowing corporations to tailor the interactive experience to their unique requirements and workflows."
    },
    {
      question: "What is the warranty and maintenance policy for Coltec's corporate solutions?",
      answer: "Coltec offers a robust warranty covering parts and labor for up to 3 years, along with regular maintenance services and timely software updates to ensure optimal performance of their corporate solutions."
    },
  ];
  