export const faqData = [
  {
    question: "How can Coltec's solutions enhance classroom interactivity?",
    answer: "Coltec's interactive flat panels enable dynamic teaching through multi-touch capabilities, real-time collaboration, and engaging multimedia integration, fostering a more interactive learning environment."
  },
  {
    question: "What tools does Coltec provide for remote and hybrid learning?",
    answer: "Coltec offers seamless integration with video conferencing platforms, cloud-based collaboration tools, and wireless connectivity options to support effective remote and hybrid teaching and learning."
  },
  {
    question: "Are Coltec's educational solutions compatible with existing school infrastructure?",
    answer: "Yes, Coltec's solutions are designed to integrate smoothly with existing IT infrastructures, supporting various operating systems, network configurations, and educational software applications."
  },
  {
    question: "What professional development does Coltec offer for educators?",
    answer: "Coltec provides comprehensive training programs, including workshops, webinars, and online resources, to help educators effectively utilize interactive technologies in their teaching practices."
  },
  {
    question: "How do Coltec's solutions support personalized learning?",
    answer: "Coltec's interactive tools allow for customized lesson plans, individualized student engagement, and adaptive learning technologies that cater to diverse learning styles and paces."
  },
  {
    question: "What security measures are in place for Coltec's educational solutions?",
    answer: "Coltec ensures data privacy and security through encrypted communications, secure user authentication, and regular software updates to protect against unauthorized access and data breaches."
  },
  {
    question: "Can Coltec's solutions be used for both K-12 and higher education institutions?",
    answer: "Absolutely. Coltec's versatile solutions are tailored to meet the unique needs of K-12 schools, colleges, and universities, supporting a wide range of educational activities and administrative functions."
  },
  {
    question: "What support and maintenance services does Coltec provide for educational institutions?",
    answer: "Coltec offers dedicated technical support, regular maintenance services, and timely updates to ensure that educational institutions can rely on their interactive solutions without disruptions."
  },
];
