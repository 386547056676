import riseproductSpecs from "./riseSeries";
import evokeproductSpecs from "./evokeSeries";
import productSpecs from "./inspireSeries";

export const products = {
  "Interactive Flat Panel": [
    {
      id: 1,
      name: "COLTEC Rise | 65 Inch Size",
      title:"65 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "95,000",
      originalPrice: "1,15,000",
      slug: "65-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-65.png",
      specs: riseproductSpecs['65"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.jpg",
      ],
    },
    {
      id: 2,
      name: "COLTEC Evoke | 65 Inch Size",
      title:"65 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "95,000",
      originalPrice: "1,15,000",
      slug: "65-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-65.png",
      specs: evokeproductSpecs['65"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.jpg",
      ],
    },
    {
      id: 3,
      name: "COLTEC Inspire | 65 Inch Size",
      title:"65 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "95,000",
      originalPrice: "1,15,000",
      slug: "65-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-65.png",
      specs: productSpecs['65"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.jpg",
      ],
    },
    {
      id: 4,
      name: "COLTEC Rise | 75 Inch Size",
      title:"75 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "1,15,500",
      originalPrice: "200000",
      slug: "75-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-75.png",
      specs: riseproductSpecs['75"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.jpg",
      ],
    },
    {
      id: 5,
      name: "COLTEC Evoke | 75 Inch Size",
      title:"75 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "1,15,500",
      originalPrice: "200000",
      slug: "75-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-75.png",
      specs: evokeproductSpecs['75"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.jpg",
      ],
    },
    {
      id: 6,
      name: "COLTEC Inspire | 75 Inch Size",
      title:"75 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "1,15,500",
      originalPrice: "200000",
      slug: "75-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-75.png",
      specs: productSpecs['75"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.jpg",
      ],
    },
    {
      id: 7,
      name: "COLTEC Rise | 86 Inch Size",
      title:"86 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "2,50,000",
      originalPrice: "300000",
      slug: "86-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-86.png",
      specs: riseproductSpecs['86"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.jpg",
      ],
    },
    {
      id: 8,
      name: "COLTEC Evoke | 86 Inch Size",
      title:"86 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "2,50,000",
      originalPrice: "300000",
      slug: "86-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-86.png",
      specs: evokeproductSpecs['86"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.jpg",
      ],
    },
    {
      id: 9,
      name: "COLTEC Inspire | 86 Inch Size",
      title:"86 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "2,50,000",
      originalPrice: "300000",
      slug: "86-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-86.png",
      specs: productSpecs['86"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.jpg",
      ],
    },
    {
      id: 10,
      name: "COLTEC Rise | 98 Inch Size",
      title:"98 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "4,85,000",
      originalPrice: "500000",
      slug: "98-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-98.png",
      specs: riseproductSpecs['98"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.jpg",
      ],
    },
    {
      id: 11,
      name: "COLTEC Evoke | 98 Inch Size",
      title:"98 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "4,85,000",
      originalPrice: "500000",
      slug: "98-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-98.png",
      specs: evokeproductSpecs['98"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.jpg",
      ],
    },
    {
      id: 12,
      name: "COLTEC Inspire | 98 Inch Size",
      title:"98 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "4,85,000",
      originalPrice: "500000",
      slug: "98-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-98.png",
      specs: productSpecs['98"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.jpg",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.jpg",
      ],
    },
  ],
};
