import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/Header";
import FAQ from "../../components/FAQ";
import { faqData } from "../../utils/productFAQ";
import Footer from "../../components/Footer";
import ScrollToTopButton from "../../components/ScrollTop";
import WhatsAppMsg from "../../components/WhatsApp";
import { UserContext } from "../../utils/UserContext";
import SEO from "../../components/SEO";
import { deleteCartProduct } from "../../utils/deleteCartProduct";
import { showToast } from "../../utils/toaster";
import { HiTrash } from "react-icons/hi";
import { sendQuotation } from "../../utils/sendQuotation";

const formatPrice = (price) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(price);
};

const ProductsCart = () => {
  const { user } = useContext(UserContext); // Get user from context
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [updatingItems, setUpdatingItems] = useState([]); // To track items being updated
  const [sendingEnquiry, setSendingEnquiry] = useState(false); // To track enquiry sending state

  useEffect(() => {
    const fetchCartData = async () => {
      const userEmail = user.email;
      try {
        const response = await fetch(
          `https://devapi.qeeb.in/api/Coltec/GetCartDetailsByEmail?Email=${encodeURIComponent(
            userEmail
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch cart data");
        }

        const data = await response.json();
        console.log("Fetched cart data:", data); // Debugging line

        // Ensure cartData is always an array
        const cartItems = Array.isArray(data) ? data : [data];
        setCartData(cartItems);

        // Calculate total price
        const totalAmount = cartItems.reduce(
          (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
          0
        );
        setTotal(totalAmount);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCartData();
  }, [user]);

  const handleDelete = async (id) => {
    try {
      await deleteCartProduct(id); // Call the delete function from utils
      const newCartData = cartData.filter((item) => item.id !== id);
      setCartData(newCartData); // Remove the item from state

      // Recalculate total after deletion using the updated cart data
      const newTotal = newCartData.reduce(
        (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
        0
      );
      setTotal(newTotal);

      // Show success toaster message
      showToast("Product removed from cart successfully!", "success");
    } catch (error) {
      setError("Failed to delete product");
      showToast("Failed to remove product from cart", "error");
    }
  };

  const handleQuantityChange = async (id, currentQuantity, action) => {
    const newQuantity =
      action === "increment" ? currentQuantity + 1 : currentQuantity - 1;

    // Prevent quantity from going below 1
    if (newQuantity < 1) return;

    // Find the item to update
    const itemToUpdate = cartData.find((item) => item.id === id);
    if (!itemToUpdate) {
      showToast("Product not found in cart", "error");
      return;
    }

    // Add item to updatingItems to show loading state
    setUpdatingItems((prev) => [...prev, id]);

    try {
      // Make API call to update cart product
      const response = await fetch(
        "https://devapi.qeeb.in/api/Coltec/UpdateCartProduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id, // Product ID in the cart
            quantity: newQuantity,
            email: user.email, // User's email
            price: itemToUpdate.price,
            name: itemToUpdate.productName, // Product name
            imageUrl: itemToUpdate.imageUrl, // Product image URL
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update cart");
      }

      const data = await response.json();

      if (data.status === 1) {
        // Update cartData state
        const updatedCartData = cartData.map((item) =>
          item.id === id ? { ...item, quantity: newQuantity } : item
        );
        setCartData(updatedCartData);

        // Recalculate total using the updated cart data
        const newTotal = updatedCartData.reduce(
          (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
          0
        );
        setTotal(newTotal);

        // Show success toaster message
        showToast("Cart updated successfully!", "success");
      } else {
        throw new Error(data.message || "Failed to update cart");
      }
    } catch (error) {
      setError(error.message);
      showToast("Failed to update cart", "error");
    } finally {
      // Remove item from updatingItems
      setUpdatingItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  // New function to handle Send Enquiry button click
  const handleSendEnquiry = async () => {
    setSendingEnquiry(true); // Set loading state

    try {
      const data = await sendQuotation(user.email);
      // Assuming the API returns a success message
      showToast("Enquiry sent successfully!", "success");
      console.log("SendQuotation response:", data);
    } catch (error) {
      showToast(`Failed to send enquiry: ${error.message}`, "error");
      console.error("SendQuotation error:", error);
    } finally {
      setSendingEnquiry(false); // Reset loading state
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <div className="spin-loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <>
      <div className="about-us-page pt-10">
        <SEO
          title="Discover Coltec’s Interactive Flat Panels & Solutions"
          description="Explore Coltec's range of interactive flat panels and collaboration solutions, designed to enhance engagement and productivity in both education and business environments."
          keywords="interactive flat panels,touchscreen displays,digital whiteboards,collaboration solutions,educational technology, corporate training displays, zero bonding touch technology,multi-touch panels,remote learning solutions,hybrid classrooms,digital signage,interactive display supplier in India,best interactive flat panel for schools,business collaboration tools,high-definition interactive displays,teaching software solutions,anti-glare interactive screens, smart classroom technology"
          ogTitle="Coltec Global | Innovative Interactive Displays & Collaboration Solutions"
          ogDescription="Discover the full range of Coltec interactive flat panels, designed to revolutionize presentations, learning, and collaboration for modern classrooms and workplaces."
          ogImage="https://content.qeeb.in/coltec/products/banner-product-three.jpg"
          ogUrl="https://coltec-global.com/products"
          canonicalUrl="https://coltec-global.com/products"
          ogImageAlt="Coltec Global Product Lineup of Interactive Flat Panels"
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <Header />
        <div className="px-6 lg:px-8 lg:pt-14 pt-10 max-w-7xl mx-auto">
          <h1 className="text-black text-[32px] font-normal font-['Poppins'] leading-[48px]">
            Your Cart
          </h1>
        </div>
        <div className="px-6 lg:px-8 pt-10 max-w-7xl mx-auto">
          {cartData.length > 0 ? (
            <>
              <div className="cart-items bg-white rounded-[40px] border-2 border-black p-6">
                <div className="cart-item md:grid grid-cols-12 gap-5 mb-6 hidden">
                  <div className="item-details col-span-4">
                    <h3 className="text-black text-[24px] font-normal font-['Poppins'] leading-[38.40px] mb-5">
                      Products
                    </h3>
                  </div>
                  <div className="quantity col-span-3 text-sm text-gray-600">
                    <h3 className="text-black text-[24px] font-normal font-['Poppins'] leading-[38.40px] mb-5">
                      Quantity
                    </h3>
                  </div>
                  <div className="price col-span-3 text-lg font-semibold text-gray-800">
                    <h3 className="text-black text-[24px] font-normal font-['Poppins'] leading-[38.40px] mb-5">
                      Price
                    </h3>
                  </div>
                  <div className="action">
                    <h3 className="text-black text-[24px] font-normal font-['Poppins'] leading-[38.40px] mb-5">
                      Actions
                    </h3>
                  </div>
                </div>
                <div className="space-y-6">
                  {cartData.map((item) => {
                    // Use productNameRaw with fallbacks
                    const productNameRaw =
                      item.productName || "Unnamed Product";
                    const [productName, panelSize] = productNameRaw.includes(
                      "|"
                    )
                      ? productNameRaw.split("|").map((str) => str.trim())
                      : [productNameRaw, ""];

                    return (
                      <div
                        key={item.id}
                        className="cart-item grid grid-cols-12 md:gap-5 gap-3"
                      >
                        <div className="item-details md:col-span-4 col-span-full">
                          <div className="flex items-center space-x-5">
                            <div className="bg-white rounded-2xl shadow-[0px_4px_20px_0px_rgba(13,13,13,0.15)] border p-4">
                              <img
                                src={item.imageUrl}
                                alt={productName}
                                className="w-32 h-32 object-contain rounded-lg"
                              />
                            </div>
                            <div>
                              <h3 className="text-lg font-medium">
                                {productName}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {panelSize}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="quantity md:col-span-3 col-span-6 text-sm text-gray-600">
                          <div className="flex items-center md:h-32 space-x-4">
                            <div className="border border-black px-6 py-0.5 rounded-full flex items-center">
                              <button
                                className="text-lg focus:outline-none"
                                onClick={() =>
                                  handleQuantityChange(
                                    item.id,
                                    item.quantity,
                                    "decrement"
                                  )
                                }
                                disabled={
                                  item.quantity === 1 ||
                                  updatingItems.includes(item.id)
                                }
                              >
                                -
                              </button>
                              <span className="mx-4">{item.quantity}</span>
                              <button
                                className="text-lg focus:outline-none"
                                onClick={() =>
                                  handleQuantityChange(
                                    item.id,
                                    item.quantity,
                                    "increment"
                                  )
                                }
                                disabled={updatingItems.includes(item.id)}
                              >
                                {updatingItems.includes(item.id) ? "+" : "+"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="price md:col-span-3 col-span-5 text-lg font-semibold text-gray-800">
                          <div className="flex items-center md:h-32 space-x-4">
                            {formatPrice(
                              (item.price || 0) * (item.quantity || 0)
                            )}
                          </div>
                        </div>

                        <div className="action">
                          <div className="flex items-center md:h-32 space-x-4">
                            <button
                              onClick={() => handleDelete(item.id)}
                              className="text-black hover:text-red-700"
                            >
                              <HiTrash className="text-[24px]" />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="cart-summary bg-gray-100 p-6 mt-6 rounded-md">
                <div className="flex justify-between md:text-lg">
                  <span>Subtotal</span>
                  <span>{formatPrice(total)}</span>
                </div>
                <div className="flex justify-between md:text-lg mt-2">
                  <span>Shipping</span>
                  <span>Calculated at next step</span>
                </div>
                <div className="flex justify-between md:text-2xl font-semibold mt-4">
                  <span>Total</span>
                  <span>{formatPrice(total)} + GST</span>
                </div>
                <div className="text-center mt-6">
                  <button
                    onClick={handleSendEnquiry} // Attach the click handler
                    className={`bg-blue-600 text-white py-2 px-6 rounded-full ${
                      sendingEnquiry
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-blue-700"
                    }`}
                    disabled={sendingEnquiry} // Disable button while sending
                  >
                    {sendingEnquiry ? "Sending..." : "Send Enquiry"}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="pb-8">Your cart is empty.</div>
          )}
        </div>
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
          <FAQ data={faqData} />
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default ProductsCart;
