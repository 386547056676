import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import ScrollToTopButton from "../components/ScrollTop";
import WhatsAppMsg from "../components/WhatsApp";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="about-us-page">
        <SEO
          title="Privacy Policy | Coltec Global"
          description="Learn how Coltec Global collects, uses, and protects your personal data in compliance with GDPR."
          keywords="Privacy Policy, GDPR Compliance, Data Protection, Coltec Global"
          ogTitle="Privacy Policy | Coltec Global"
          ogDescription="Understand Coltec Global's approach to data privacy and protection."
          ogUrl="https://coltec-global.com/privacy-policy"
          ogType="website"
        />
        <Header />
        <div className="max-w-7xl px-5 lg:px-8 m-auto mx-auto mt-24 pb-12">
          <main className="p-8 bg-white shadow-lg border rounded-2xl">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <p className="mb-2">
              <strong>Effective Date:</strong> January 1, 2025
            </p>
            <p className="mb-2 text-[15px]">
              Coltec Global ("we," "our," "us") operates the website
              <a href="https://www.coltec-global.com" className="text-blue-500">
                {" "}
                www.coltec-global.com{" "}
              </a>
              (the "Website") and is committed to protecting your personal data.
              This Privacy Policy explains how we collect, use, and protect your
              personal data in compliance with the General Data Protection
              Regulation (GDPR).
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              1. Data Controller Information
            </h2>
            <p className="mb-2 text-[15px]">Coltec Global</p>
            <address className="text-[15px]">
              Survey No.75/A, Gagan Pahad, <br />
              Rajendra Nagar Municipality, <br />
              Ranga Reddy District, TS, INDIA <br />
              Email:{" "}
              <a href="mailto:info@coltec-global.com" className="text-blue-500">
                info@coltec-global.com
              </a>
            </address>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              2. What Data We Collect
            </h2>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                Identity Data: Name, job title, company name.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Contact Data: Email address, phone number, physical address.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Technical Data: IP address, browser type, operating system, and
                other usage data collected via cookies.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Marketing and Communication Data: Preferences for receiving
                marketing information.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Transaction Data: Details of products purchased and payment
                history.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              3. How We Collect Your Data
            </h2>
            <p className="mb-2">We collect personal data through:</p>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                Direct Interactions: Forms on our website, contact, or
                purchases.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Automated Technologies: Cookies and tracking technologies.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Third Parties: Payment processors and analytics providers.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              4. How We Use Your Data
            </h2>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                To process and deliver orders, including payment and shipping.
              </li>
              <li className="leading-[1.8] text-[15px]">
                To respond to inquiries or support requests.
              </li>
              <li className="leading-[1.8] text-[15px]">
                To send marketing communications (opt-in required).
              </li>
              <li className="leading-[1.8] text-[15px]">
                To improve our website, products, and services through
                analytics.
              </li>
              <li className="leading-[1.8] text-[15px]">
                To comply with legal and regulatory requirements.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              5. Legal Basis for Processing Your Data
            </h2>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                Contractual Necessity: To perform a contract with you.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Legitimate Interests: To improve our website and protect against
                fraud.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Consent: For marketing communications.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Legal Obligation: To comply with laws and regulations.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">6. Data Sharing</h2>
            <p className="mb-2">We may share your data with:</p>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                Service Providers: For hosting, payment processing, shipping,
                and customer support.{" "}
              </li>
              <li className="leading-[1.8] text-[15px]">
                Analytics Providers: To improve website performance (e.g.,
                Google Analytics).
              </li>
              <li className="leading-[1.8] text-[15px]">
                {" "}
                Legal Authorities: When required by law.
              </li>
            </ul>
            <p className="mb-2">
              We do not sell your personal data to third parties.{" "}
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              7. Data Retention
            </h2>
            <p>
              We retain personal data for as long as necessary to fulfill the
              purposes outlined in this policy or to comply with legal
              obligations.
            </p>
            <h2 className="text-xl font-semibold mt-8 mb-4">
              8. Your Rights Under GDPR
            </h2>
            <p className="mb-2">
              As a user in the European Economic Area (EEA), you have the
              following rights:
            </p>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                Right to Access: Request access to your personal data.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Right to Rectification: Correct inaccuracies in your personal
                data.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Right to Erasure: Request deletion of your data (“right to be
                forgotten”).
              </li>
              <li className="leading-[1.8] text-[15px]">
                Right to Restrict Processing: Limit the processing of your data.{" "}
              </li>
              <li className="leading-[1.8] text-[15px]">
                Right to Data Portability: Receive your data in a portable
                format.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Right to Object: Object to processing based on legitimate
                interests.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Right to Withdraw Consent: Withdraw consent for marketing
                communications.
              </li>
            </ul>
            <p className="mb-2">
              To exercise your rights, contact us at{" "}
              <a href="mailto:info@coltec-global.com" className="text-blue-500">
                info@coltec-global.com
              </a>
            </p>
            <h2 className="text-xl font-semibold mt-8 mb-4">
              9. Cookies and Tracking
            </h2>
            <p className="mb-2">
              Our website uses cookies to enhance user experience and analyze
              website performance.
            </p>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                Essential Cookies: Necessary for website functionality.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Analytics Cookies: Track user behavior to improve our services.
              </li>
              <li className="leading-[1.8] text-[15px]">
                Marketing Cookies: Tailor advertising to your interests.
              </li>
            </ul>
            <p className="mb-2">
              You can manage or disable cookies through your browser settings.
            </p>
            <h2 className="text-xl font-semibold mt-8 mb-4">
              10. Data Security
            </h2>
            <p className="mb-2">
              We implement appropriate technical and organizational measures to
              protect your data against unauthorized access, alteration,
              disclosure, or destruction.
            </p>
            <h2 className="text-xl font-semibold mt-8 mb-4">
              11. International Data Transfers
            </h2>
            <p className="mb-2">
              If you are located outside of India, your personal data may be
              transferred to and processed in India or other countries. We
              ensure such transfers comply with GDPR requirements through
              appropriate safeguards.
            </p>
            <h2 className="text-xl font-semibold mt-8 mb-4">
              12. Changes to This Privacy Policy
            </h2>
            <p className="mb-2">
              We may update this Privacy Policy from time to time. Updates will
              be posted on this page, and significant changes will be
              communicated via email where applicable.
            </p>
            <h2 className="text-xl font-semibold mt-8 mb-4">13. Contact Us</h2>
            <p className="mb-2">
              If you have questions or concerns about this Privacy Policy or how
              we handle your data, please contact us:
            </p>
            <address className="text-[15px]">
              Email:{" "}
              <a href="mailto:info@coltec-global.com" className="text-blue-500">
                info@coltec-global.com
              </a>
              <br />
              Address: Survey No.75/A, Gagan Pahad, <br />
              Rajendra Nagar Municipality, <br />
              Ranga Reddy District, TS, INDIA <br />
            </address>
            <h2 className="text-xl font-semibold mt-8 mb-4">
              14. Additional Information for GDPR Compliance
            </h2>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8] text-[15px]">
                Supervisory Authority Contact Details: If you are not satisfied
                with our response to a privacy concern, you have the right to
                lodge a complaint with your local Data Protection Authority
                (DPA).
              </li>
              <li className="leading-[1.8] text-[15px]">
                Profiling and Automated Decision-Making: We do not use automated
                decision-making or profiling to make significant decisions about
                individuals. If this changes, we will update this policy
                accordingly.
              </li>
            </ul>
          </main>
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg/>
      </div>
    </>
  );
};

export default PrivacyPolicy;
